@import "./src/assets/scss/variables";

.tasks-project-kanban-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;

  &__item {
    width: calc(100% / 4 - 10px);
  }

  &__mobile-nav {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    span {
      font-weight: 700;
      font-size: 13px;
      text-transform: uppercase;
    }
    button {
      width: 32px;
      height: 32px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      &:disabled {
        opacity: 0.5;
      }
    }
  }

  &__mobile-heading {
    display: none;
    align-items: center;
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 15px;
    img {
      margin: 0 12px 0 10px;
    }
  }
}

.tasks-project-kanban-grid-item {
  &__header {
    font-family: Inter;
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    color: $color_black;
    display: flex;
    align-content: center;
    margin-bottom: 10px;
  }

  &__title {

  }

  &__count {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .tasks-project-kanban-grid {
    &__mobile-nav {
      display: flex;
    }
    &__mobile-heading {
      display: flex;
    }
  }
  .tasks-project-kanban-grid-item {
    padding: 0 10px;
    &__header {
      display: none;
    }
  }
}