.client_kanban_request {
  h4 {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    max-width: 600px;
    margin: 10px auto 0 auto;
  }
  .modal__container {
    width: 920px;
    padding-bottom: 60px;
    padding-top: 50px;
  }
  .modal__container__header {
    h2 {
      flex-grow: 1;
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
    }
  }

  &__body {
    display: flex;
  }
  &__col {
    width: 50%;
  }
  &__image {
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
  }
  &__description {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    max-width: 305px;
    margin: 10px auto 0 auto;
    height: 84px;
  }
  &__button {
    width: 220px;
    margin: 37px auto 0 auto;
    display: block;
    &:hover {
      opacity: 0.6;
    }
  }
}

@media only screen and (max-width: 500px) {
  .client_kanban_request {
    h4 {
      font-weight: 500;
      font-size: 13px;
    }
    &__title {
      font-size: 13px;
      img {
        display: inline-block !important;
        width: 70px;
        position: relative;
        bottom: -13px;
        margin-right: 9px;
      }
    }
    &__description {
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
    }
    .modal__container {

    }
    &__body {
      flex-direction: column;
      align-items: center;
    }
    &__col {
      width: 100%;
      margin-top: 20px;
    }
    &__image {
      display: none;
    }
    &__button {
      margin-top: 10px;
      width: 100%;
    }
  }
}