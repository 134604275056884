.client-kanban-create-button {
  width: 270px;
  height: 168px;
  background: linear-gradient(0deg, #EEF0F4, #EEF0F4), #FFFFFF;
  border: 1px dashed #77A193;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #FFFFFF;
  }
  &__container {
    display: flex;
    align-items: center;
  }
  &__icon {
    height: 35px;
    width: 35px;
    background: #000000;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__text {
    margin-left: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000000;
  }
}

@media only screen and (max-width: 1500px) {
  .client-kanban-create-button {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .client-kanban-create-button {
    height: 70px;
    width: calc(100% - 20px);
    margin-left: 10px;
  }
}