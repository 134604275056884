.test-for-cors {
  margin: 20px;
  .react-select {
    width: 200px;
  }
  input {
    margin-left: 10px;
    width: 600px;
  }
  .textarea {
    margin-top: 20px;
  }
  .btn-black {
    margin-top: 10px;
    width: 200px;
  }
}