.offer-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  border-top: 2px solid #F3F5FA;
  margin-top: 10px;
  &__left {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #81889A;
    padding-right: 10px;
  }
  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__price {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-right: 15px;
    display: block;
    text-wrap: nowrap;
    b {
      font-size: 21px;
    }
  }
  &__button {
    margin-top: 18px;
    padding: 12px 68px;
    border-radius: 100px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #FFFCFC;
    background: #000000;
    border: none;
    cursor: pointer;
    &:disabled {
      opacity: 0.3;
    }
    &:hover {
      opacity: 0.6;
    }
  }
}

@media only screen and (max-width: 500px) {
  .offer-footer {
    flex-direction: column;
    &__price {
      margin-top: 20px;
    }
    &__button {
      margin-top: 20px;
    }
  }
}