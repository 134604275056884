.payment-plan-information {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-left-side {
    border-radius: 10px;
    background: #2361FF;
    width: 270px;
    height: 66px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Inter, sans-serif;
    font-weight: 500;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 20px;
    position: relative;
    &-name {
      font-size: 17px;
    }
    &-period {
      letter-spacing: -0.5px;
      font-size: 11px;
      margin-top: 6px;
    }
    &-valid-to {
      position: absolute;
      color: #99B6FF;
      font-size: 11px;
      letter-spacing: -0.5px;
      right: 12px;
      top: 22px;
    }
    &-cancel {
      position: absolute;
      color: #99B6FF;
      font-size: 11px;
      letter-spacing: -0.5px;
      right: 12px;
      bottom: 10px;
      cursor: pointer;
      &:hover {
        color: #FFFFFF;
        text-decoration: underline;
      }
    }
  }
  &-right-side {
    border-radius: 10px;
    border: 1px solid #D2DAEC;
    background: #FFF;
    height: 66px;
    flex-shrink: 0;
    flex-grow: 1;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 20px;
    position: relative;
    &-total-balance {
      font-family: Inter, sans-serif;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.5px;
    }
    &-balances {
      margin-top: 3px;
      display: flex;
      align-items: center;
      span {
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        margin-right: 30px;
        display: flex;
        align-items: center;
        em {
          color: #7B8DB7;
          font-size: 11px;
          margin-left: 5px;
        }
        img {
          margin-left: 10px;
        }
      }
    }
    &-how-use {
      font-family: Inter, sans-serif;
      font-size: 10px;
      font-weight: 300;
      position: absolute;
      top: 12px;
      right: 20px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.payment-plan-information-modal {
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  &__text {
    h3 {
      font-family: Inter, sans-serif;
      font-size: 15px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.5px;
      margin-top: 25px;
    }
    p {
      margin-top: 8px;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.5px;
    }
  }
  &__checkbox {
    .checkbox__item {
      align-items: center;
    }
  }
}