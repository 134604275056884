.choose_payment_plan {
  &__mobile-button {
    display: none;
  }
  .modal__container__header {
    display: none;
  }
  .modal__container {
    background: #F3F5FA;
    padding: 30px;
  }
  &__header {
    position: relative;
    display: flex;
    justify-content: center;
  }
  &__close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  &__body {

  }
  &__desc {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 21px;
    line-height: 21px;
    text-align: center;
    margin-top: 45px;
  }
  &__plans {
    margin-top: 39px;
  }
  &__plan {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 82px;
    box-sizing: border-box;
    &-container {
      width: 100%;
      background: #FFFFFF;
      border-radius: 10px;
      margin-bottom: 15px;
    }
    &-sale {
      font-family: 'Inter', sans-serif;
      text-align: center;
      font-weight: 400;
      font-size: 10px;
      letter-spacing: 1px;
      text-transform: uppercase;
      background: #346DFF;
      color: #FFFFFF;
      border-radius: 0 0 10px 10px;
      padding: 5px 0;
    }
    &__left {
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
    &__right {
      display: flex;
    }
    &__price {
      display: flex;
      align-items: flex-start;
      margin-right: 40px;
      b {
        font-family: 'Inter', sans-serif;
        font-weight: 900;
        font-size: 42px;
        letter-spacing: -2px;
      }
      em {
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 13px;
        text-transform: uppercase;
        color: #60637E;
        margin-top: 5px;
        margin-left: 2px;
      }
    }
    button {
      font-family: 'Inter', sans-serif;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 1px;
      padding: 0 40px;
      img {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .choose_payment_plan {
    &__desc {
      font-weight: 700;
      font-size: 15px;
      line-height: 21px;
      max-width: 165px;
      margin: 36px auto 0 auto;
    }
    &__plans {
      margin-top: 11px;
    }
    &__plan {
      position: relative;
      height: auto;
      padding: 22px 0;
      button {
        display: none;
      }
      &-sale {
        font-size: 9px;
        line-height: 9px;
        text-align: left;
        padding-left: 15px;
        width: calc(100% - 35px);
        border-radius: 0 0 0 10px;
      }
      &-container {
        margin-bottom: 5px;
        height: 68px;
        position: relative;
      }
      &__left {
        font-weight: 600;
        font-size: 11px;
        padding-left: 15px;
        max-width: 66px;
      }
      &__right {
        padding-right: 20px;
      }
      &__price {
        display: flex;
        align-items: center;
        b {
          font-weight: 900;
          font-size: 24px;
        }
        em {
          color: #60637E;
          font-weight: 600;
          font-size: 11px;
          margin-top: -5px;
        }
      }
    }
    &__mobile-button {
      display: block;
      font-family: 'Inter', sans-serif;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 1px;
      height: 68px;
      border-radius: 0 10px 10px 0;
      padding: 0 10px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .choose_payment_plan__sale-text {
    .choose_payment_plan__plan {
      padding: 10px 0 6px 0;
    }
  }
}