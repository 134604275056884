@import "./src/assets/scss/variables";
.tabs {
  margin-top: 11px;
  background: $color_light;
  padding: 34px 29px;
  &__header {
    display: flex;
    &-item {
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-size: 16px;
      margin-right: 10px;
      border: 1px solid #E5EAF8;
      border-radius: 100px;
      padding: 10px 21px;
      color: #8A93AD;
      // border-bottom: 2px solid $color_light;
      cursor: pointer;
      &:hover, &-active {
        color: #2361FF;
        border: 1px solid #2361FF;
        // border-bottom: 2px solid $color_blue;
      }
    }
  }
  &__body {
    padding-top: 30px;
  }
}

.mobile-tabs {
  &__item {
    margin-top: 20px;
    padding: 20px 15px;
    background: #FFFFFF;
    border-radius: 10px;
    &-header {
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > img {
        transform: rotate(-90deg);
      }
    }
  }
  .mobile-tabs__item-active .mobile-tabs__item-icon {
    transform: rotate(90deg) !important;
  }
  &__component {
    min-height: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .tabs {
    padding: 30px 10px;
  }
}