.payment-plan-credits {
  margin-top: 50px;
  &__heading {
    font-family: Inter, sans-serif;
    h2 {
      font-size: 21px;
      font-weight: 500;
    }
    p {
      margin-top: 10px;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: -0.5px;
    }
  }
  &__items {
    margin-top: 20px;
    display: flex;
  }
  &__item {
    margin-right: 20px;
    width: 33%;
    &:last-child {
      margin-right: 0;
    }
    &-heading {
      border-radius: 10px 10px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px;
      font-family: Inter, sans-serif;
      p {
        font-size: 21px;
        font-weight: 400;
      }
      b {
        font-size: 26px;
        font-weight: 800;
        letter-spacing: -0.5px;
      }
    }
    &-body {
      background: #FFFFFF;
      border-radius: 0 0 10px 10px;
      padding: 24px 0;
      p {
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: -0.5px;
        max-width: 220px;
        margin: 0 auto;
      }

      button {
        margin: 30px auto 0 auto;
        display: block;
        padding: 14px 45px;
        border: none;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &__count {
    margin-top: 17px;
    margin-bottom: 50px;
    width: 100%;
    height: 92px;
    border-radius: 10 px;
    background: #FFF;
    display: flex;
    align-items: center;
    padding: 0 34px;
    justify-content: space-between;
  }
  &__credits {
    display: flex;
    align-items: center;
    span {
      font-family: Inter, sans-serif;
      color: #A3A9B9;
      font-size: 15px;
      font-weight: 400;
      margin-right: 11px;
    }
    button {
      font-family: Inter, sans-serif;
      font-size: 21px;
      line-height: 0;
      font-weight: 400;
      text-transform: uppercase;
      color: #FFFFFF;
      width: 42px;
      height: 42px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #000;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      border: none;
    }
  }
  &__input {
    width: 128px;
    height: 42px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #EEEFF6;
    background: #F3F5FA;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter, sans-serif;
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
  }
  &__price {
    button {
      width: 174px;
      height: 42px;
      flex-shrink: 0;
      border-radius: 100px;
      background: #000;
      color: #FFF;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;
      border: none;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}