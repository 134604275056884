.task-deliverables-for-offer {
  padding: 20px;
  background: rgb(255, 255, 255);
  margin-bottom: 10px;
  border-radius: 10px;
  &__dropdown {
    position: relative;
    img {
      cursor: pointer;
    }
  }
  .offerHeader__dropdown-container {
    top: 22px;
    min-width: 150px;
  }

  &__items {
    border-top: 1px solid #CAD5DD;
    padding: 5px 0;
    margin-top: 5px;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 15px;
  }
  &__item-left {
    display: flex;
    align-items: center;
    span {
      font-family: Inter, sans-serif;
      font-size: 11px;
      letter-spacing: -0.5;
      font-weight: 500;
      margin-left: 5px;
    }
  }
  &__item-right {
    display: flex;
    align-items: center;
  }
  &__icon {
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    border-radius: 5px;
    flex-shrink: 0;
  }
  &__button {
    height: 26px;
    background: #FFFFFF;
    border: 1px solid #E5EAF8;
    color: #7389C3;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 10px;
    text-wrap: nowrap;
    &-squire {
      width: 26px;
    }
    &:hover {
      background: #E5EAF8;
    }
  }
}