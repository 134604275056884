.my-node-enter {
  opacity: 0
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 300ms
}
.my-node-exit {
  opacity: 1
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.scale-enter {
  opacity: 0;
  transform: scale(0.9);
}
.scale-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.scale-exit {
  opacity: 1;
}
.scale-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


.slide-enter {
  opacity: 0;
  transform: translateX(-20px);
}
.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.slide-exit {
  opacity: 1;
}
.slide-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 500ms, transform 500ms;
}
.mdb {
  display: none;
}

.fade-enter {
  opacity: 0.01;
  position: relative;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: all 300ms ease-in;
}

.fade-enter.fade-enter-done {
}

.fade-exit {
  opacity: 1;
  position: relative;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-out;
}

.fade-exit.fade-exit-done {

}


