.create_legal_task {
  .modal__container__header h2 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
  }
  .checkbox__item {
    align-items: center;
  }
  p {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    margin-top: 20px;
  }
}