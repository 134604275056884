.task-tab-list {
  &__table {
    width: 100%;
  }
  th {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #A3A9B9;
    text-align: left;
    padding: 5px;
  }
  &__text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.5px;
    max-width: 250px;
  }
  &__label {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #A3A9B9;
    text-align: left;
    padding: 5px 5px 10px 0;
  }
  &__mobile-tr {
    border-bottom: 2px solid #F3F5FA;
    &:last-child {
      border: none !important;
    }
  }
  td {
    padding: 10px 5px;
  }
  &__button {
    width: 100%;
    height: 55px;
    background: linear-gradient(0deg, #EEF0F4, #EEF0F4), #FFFFFF;
    border: 1px dashed #77A193;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    b {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #000000;
      margin-left: 10px;
    }
  }
  &__circle {
    width: 35px;
    height: 35px;
    background: #000000;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__provider {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &-image {
      width: 80px;
      height: 80px;
      border-radius: 10px;
    }
    &-left {
      display: flex;
    }
    &-info {
      padding: 10px 0 10px 15px;
      h4 {
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 400;
      }
      em {
        color: #8A93AD;
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 400;
        margin-top: 5px;
        display: block;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 10px 0;
      b, a {
        display: block;
      }
      b {
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: -0.5px;
      }
      a {
        color: #2361FF;
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 500;
        margin-top: 15px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__no-task {
    padding: 10px 20px;
    h2 {
      color: #8A93AD;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }
    p {
      color: #81889A;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.5px;
      margin-top: 8px;
    }
  }
  &__top-info {
    color: #81889A;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 30px;
  }

  &__paid-item {
    font-family: Inter, sans-serif;
    width: 42px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 100px;
    font-size: 11px;
    letter-spacing: -0.5px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
}