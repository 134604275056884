.dropdown__notifications {
  display: flex;
  align-items: center;
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    &-icon {

    }
    &-text {
      max-width: 214px;
      margin-left: 16px;
      p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
      }
      em {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #A2A9B9;
        margin-top: 5px;
      }
    }
  }
  &__relative {
    display: flex;
    position: relative;
    &:hover {
      display: block;
      .dropdown__notifications__block {
        display: inherit !important;
      }
    }
  }
  &__chat {
    width: 16px;
    height: 16px;
    background: url("../../../../assets/images/kanbanChatIcon.svg");
    display: flex !important;
    align-items: center;
    justify-content: center;
    &-count {
      font-size: 9px;
      color: #FFFFFF;
      margin-top: -2px;
    }
  }
  &__block {
    position: absolute;
    display: none;
    top: 18px;
    left: -150px;
    z-index: 2;
    > div {
      max-height: 300px;
      overflow: auto;
      position: relative;
      width: 224px;
      background: #FFFFFF;
      box-shadow: 0px 4px 24px rgba(15, 31, 74, 0.2);
      border-radius: 10px;
      padding: 25px 15px;
    }
    h4 {
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.5px;
      display: flex;
      align-items: center;
    }
    p {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: -0.5px;
      margin-top: 5px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .dropdown__notifications__block {
    left: inherit;
    right: -15px !important;
    > div {
      width: 260px;
    }
  }
}