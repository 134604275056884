.firstStep {
  width: 580px;
  margin: 0 auto;
}
.firstStepContainer {
  background: #FFFFFF;
  padding: 26px 22px;
  border: 1px solid #EAE9F2;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 10px;
}
.firstStepBlock {
  margin-bottom: 40px;
}
.firstStepBlockHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
}
.firstStepBlockHeading h4 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.firstStepBlockTooltip {
  width: 26px;
  height: 26px;
  background: #000000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 15px;
  position: relative;
  &:hover .firstStepBlockTooltipDropDown {
    display: block !important;
  }
}
.firstStepBlockTooltipDropDown {
  position: absolute;
  width: 224px;
  padding: 15px;
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(15, 31, 74, 0.2);
  border-radius: 10px;
  right: 30px;
  top: -15px;
  font-size: 13px;
  line-height: 16px;
  display: none;
}
.firstStepBlockIcon {

}
.firstStepBlockBody {

}
.firstStepBlockBodyItem {
  margin-bottom: 15px;
}
.firstStepBlockBodyItem span {
  margin-left: 14px;
  font-family: Inter, sans-serif;
  font-size: 15px;
  line-height: 16px;
  font-weight: 400;
}
.nextButton {
  width: 100%;
  height: 68px;
  background: #000000;
  border: none;
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  border-radius: 100px;
  &:hover {
    opacity: 0.8;
    box-shadow: 0 5px 5px rgba(60, 89, 107, 0.3);
  }
  &:disabled {
    opacity: 0.5 !important;
  }
}
.nextButton span {
  color: #FFFFFF;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.prevButton {
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000000;
  background: none;
  border: none;
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  &:hover {
    text-decoration: underline;
  }
}
.customRequestBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &__text {

  }
  &__heading {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    text-transform: uppercase;
    color: #000000;

  }
  &__description {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #8D97AF;
  }
  &__icon {
    width: 48px;
    height: 48px;
    background: #000000;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .firstStep {
    width: inherit;
  }
  .firstStepContainer {
    padding: 30px 20px;
  }
  .firstStepBlockHeading {
    h4 {
      font-weight: 700;
      font-size: 15px;
    }
  }
  .firstStepBlockBodyItem {
    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .nextButton {
    height: 58px;
  }
  .firstStepBlockTooltipDropDown {
    display: none !important;
    opacity: 0 !important;
  }
}