@import "./src/assets/scss/variables";

.modal {
  height: 100vh;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  top: 0;
  left: 0;
  &__container {
      display: flex;
      width: 610px;
      background: $color_light;
      padding: 40px;
      z-index: 3;
      border-radius: $radius;
      flex-direction: column;
      max-height: 90vh;
      overflow: auto;
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
            &-close {
              cursor: pointer
            }
      }
  }
  &__bg {
    z-index: 2;
    background: rgba(81, 81, 81, 0.9);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0
  }
  &__body {
    margin-top: 10px;
    overflow: auto;
    padding: 0 10px;
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
      outline: 1px solid slategrey;
    }
  }
}

@media only screen and (max-width: 500px) {
  .modal {
    &__container {
      max-width: 95% !important;
    }
  }
  &__bg {

  }
}
