@import "./src/assets/scss/variables";
.react-select {
  position: relative;
  &__control {
    height: 42px;
    border: 1px solid #B4BED9 !important;
    box-sizing: border-box;
    border-radius: 5px;
  }
  &__menu {
    position: relative;
    z-index: 9 !important;
    font-size: 13px;
    background: #ffffff;
  }
  &__value-container {
    font-family: 'Inter';
    font-size: 13px;
    padding-left: 15px;
  }
  &__label {
    font-family: Inter;
    position: absolute;
    background: #fff;
    padding: 0 8px;
    top: -4px;
    left: 12px;
    font-size: 11px;
    z-index: 1;
    color: $color_grey_light;
  }
}
