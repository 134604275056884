.documents {
  h2 {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 26px;
    font-weight: 700;
  }
  h3 {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-top: 50px;
    text-transform: uppercase;
  }
  &__container {
    display: flex;
  }
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__left {
    width: 80%;
    flex-shrink: 0;
  }
  &__right {
    width: 20%;
    margin-left: 20px;
  }
  &__search {
    border-radius: 5px;
    border: 1px solid #B4BED9;
    width: 100%;
    height: 42px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 13px;
    margin-top: 20px;
    input {
      font-family: 'Inter', sans-serif;
      flex-grow: 1;
      margin-right: 5px;
      border: 0;
      height: 100%;
      outline: 0;
      font-size: 13px;
      letter-spacing: -0.5px;
    }
    img {
      flex-shrink: 0;
      cursor: pointer;
    }
  }

  &__table {
    border-spacing: 0 5px;
    border-collapse: inherit;
    width: 100%;
    margin-top: 15px;
    th {
      color: #A3A9B9;
      font-family: 'Inter', sans-serif;
      font-size: 11px;
      font-weight: 400;
      text-align: left;
      padding-left: 12px;
      &:first-child {
        padding-left: 20px;
      }
    }
    td {
      padding: 20px 12px;
    }
    tr {
      background: #FFFFFF;
      border-radius: 10px;
      td:first-child {
        border-radius: 10px 0 0 10px;
        padding-left: 20px;
      }
      td:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
    &-project {
      vertical-align: middle;
      color: #000;
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 17px;
    }
    &-file {
      //width: 28%;
      vertical-align: middle;
      > div {
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
        em {
          display: block;
          color: #000;
          font-family: 'Inter', sans-serif;
          font-size: 13px;
          font-weight: 400;
          line-height: 21px;
          overflow-wrap: anywhere;
        }
        span {
          display: block;
          color: #8A93AD;
          font-family: Roboto, sans-serif;
          font-size: 11px;
          font-weight: 400;
          line-height: 15px;
        }
      }
    }
    &-author {
      vertical-align: middle;
      img {
        width: 35px;
        height: 35px;
        flex-shrink: 0;
      }
      span {
        color: #000;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 21px;
        margin-top: 10px;
        margin-left: 7px;
      }
    }
    &-options {
      vertical-align: middle;
      //width: 15%;
      a {
        color: #4569E9;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-weight: 600;
      }
    }
    &-actions {
      vertical-align: middle;
      width: 115px;
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 10px;
        img {
          cursor: pointer;
        }
      }
    }
  }
  &__download {
    position: relative;
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      background: #FFFFFF;
      position: absolute;
      transform: rotate(45deg);
      bottom: -8px;
      left: 17px;
      z-index: 4;
    }
  }
  &__dropdown {
    box-shadow: 0px 4px 24px rgba(15, 31, 74, 0.2);
    padding: 12px 14px;
    font-size: 11px;
    font-family: 'Inter', sans-serif;
    border-radius: 10px;
    position: absolute;
    bottom: -37px;
    left: -17px;
    background: #FFF;
    z-index: 3;
    cursor: pointer;
  }
  &__no-items {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__tabs {
    display: flex;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  &__tab {
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    margin-right: 15px;
    border-radius: 20px;
    padding: 0 22px;
    border: 1px solid #E5EAF8;
    cursor: pointer;
    &-active {
      color: #2361FF;
      border: 1px solid #2361FF;
    }
  }
}

.bookmarkBlock {
  border-radius: 10px;
  background: #FFF;
  width: 100%;
  &__checkbox {
    padding: 12px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &-label {
      color: #000;
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  &__squire {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    background: #f3f5fa;
    border-radius: 4px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-active {
      width: 8px;
      height: 8px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #2361FF;
      box-shadow: 0px 0px 4px 0px rgba(155, 172, 233, 0.25) inset;
    }
  }
  &__hr {
    width: 100%;
    height: 1px;
    background: #F3F5FA;
  }
}