.deliverableView {
  position: relative;
  &__header {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__window {
    position: fixed;
    width: 500px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 2px solid #bbbbbb;
    right: 5px;
    bottom: 10px;
    padding: 15px;
    * {
      font-family: "Inter", sans-serif;
    }
    img {
      width: 35px;
      cursor: pointer;
    }
    h4 {
      font-size: 18px;
      font-weight: bold;
    }
    a {
      color: #2361ff;
      text-decoration: underline;
      display: block;
      font-size: 14px;
      margin-bottom: 5px;
    }
    span {
      display: block;
      font-size: 13px;
      margin-bottom: 3px;
    }
  }
  &__iframe {
    height: calc(100vh - 65px);
  }
  &__buttons {
    margin-top: 15px;
  }
  &__minimized {
    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      button {
        margin: 5px 0 0 0 ;
      }
    }
  }
}

.googleLNButton {
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  margin: 2px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  cursor: pointer;
}