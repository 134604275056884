.payment-plan-items {
  &__switch {
    width: max-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 40px auto 0 auto;
    span {
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.5px;
      margin: 0 10px;
      transition: all 0.5s ease-out 0.1s;
    }
  }
  &__button {
    flex-shrink: 0;
    width: 51px;
    height: 28px;
    border-radius: 8px;
    border: 1px solid #D2DAEC;
    background: #FFFFFF;
    display: flex;
    align-items: center;
  }
  &__circle {
    border-radius: 8px;
    background: #2361FF;
    width: 24px;
    height: 24px;
    margin: 0 1px;
  }
  &__plans {
    display: flex;
    align-items: flex-start;
    margin-top: 17px;
  }
  &__plan {
    width: 270px;
    margin-right: 20px;
    &-desc {
      background: #37865B;
      color: #FFF;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 9px;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 6px 0;
    }
    &-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      background: #2361FF;
      border-radius: 10px 10px 0 0;
      span {
        color: #FFFFFF;
        font-family: Inter, sans-serif;
        font-size: 21px;
        font-weight: 500;
      }
    }
    &-body {
      background: #FFFFFF;
      padding-bottom: 15px;
      border-radius: 0 0 10px 10px;
    }
    &-list {
      padding: 25px 15px 10px 15px;
      li {
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: -0.5px;
        list-style: disc;
        margin-left: 15px;
        margin-top: 5px;
        b {
          font-weight: 600;
        }
      }
    }
    &-more {
      cursor: pointer;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -1px;
      display: flex;
      justify-content: center;
      img {
        margin-left: 4px;
      }
    }
    &-hr {
      width: 85%;
      height: 1px;
      background: #DAE4F9;
      margin: 10px auto 0 auto;
    }
    &-price {
      width: max-content;
      margin: 30px auto 0 auto;
      font-family: Inter, sans-serif;
      letter-spacing: -1px;
      font-weight: 700;
      b {
        font-size: 36px;
        font-weight: 900;
      }
      em {
        font-size: 11px;
        text-transform: uppercase;
      }
    }
    &-button {
      width: 174px;
      height: 42px;
      color: #FFF;
      border-radius: 100px;
      background: #000000;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;
      margin: 20px auto;
      &:hover {
        opacity: 0.7;
      }
      &:disabled {
        background: #C6D6DF;
        border: none;
      }
    }
  }
}