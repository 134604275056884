@import "src/assets/scss/variables";

.settings {
  h1 {
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    margin-bottom: 40px;
  }
  .settings__container {
    display: flex;
    justify-content: space-between;
  }
  .settings__body {
    flex-grow: 1;
  }
  .settings__nav {
    border-radius: 20px;
    padding: 32px 20px;
    background: #ffffff;
    min-width: 300px;
    height: fit-content;
    margin-left: 30px;
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      cursor: pointer;
      text-decoration: none;
      &:visited {
        color: #000000;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &.active {
        span {
          color: $color_blue;
          font-weight: 600;
        }
      }
      img {
        margin-right: 10px;
      }
      span {
        font-family: Inter;
        font-size: 13px;
        line-height: 21px;
      }
    }
  }
}
