.subscriptions {
  &__table {
    width: 100%;
    &-thead {
      display: flex;
      width: 100%;
    }
    &-tbody {
      display: flex;
      width: 100%;
      background: linear-gradient(83.7deg, #83A6FF -5.08%, #2361FF 96.91%);
      border-radius: 10px;
      height: 123px;
      padding-top: 33px;
      margin-bottom: 10px;
      &-gray {
        background: #808080 !important;
      }
    }
    &-type-1 {
      background: linear-gradient(83.7deg, #83A6FF -5.08%, #2361FF 96.91%);
      .subscriptions__thead-cols-w40 {

      }
    }
    &-type-2 {
      background: linear-gradient(83.7deg, #70BFBB -5.08%, #1D8F21 96.91%);
    }
    &-type-3 {
      background: linear-gradient(83.7deg, #FF83F3 -5.08%, #2361FF 96.91%);
    }
  }
  &__edit {
    font-size: 13px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin-top: 18px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &__thead-cols {
    width: 15%;
    display: flex;
    flex-direction: column;
    &-w40 {
      width: 40%;
      padding-left: 40px;
    }
    &-w30 {
      width: 30%;
    }
  }
  &__th {
    font-size: 11px;
    line-height: 13px;
    color: #A3A9B9;
    padding-bottom: 10px;
  }
  &__h4 {
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    font-weight: 700;
    margin-bottom: 5px;
  }
  &__span {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #FFFFFF;
  }
  &__date {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #FFFFFF;
  }
  &__actions {
    display: flex;
    flex-direction: row;
  }
  &__questions {
    margin-left: 15px;
  }
  &__card {
    display: flex;
    align-items: flex-start;
    img {
      margin-right: 8px;
    }
    p {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.5px;
      color: #FFFFFF;
      margin-top: -2px;
    }
    &-link {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.5px;
      color: #FFFFFF;
      display: block;
      margin-top: 6px;
    }
  }
  &__button {
    cursor: pointer;
    &-container {
      background: linear-gradient(0deg, #EEF0F4, #EEF0F4), #FFFFFF;
      border: 1px dashed #77A193;
      box-sizing: border-box;
      border-radius: 10px;
      height: 123px;
      width: 100%;
      display: flex;
      align-items: center;
    }
    &-icon {
      background: #000000;
      border-radius: 5px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 40px;
      margin-right: 10px;
    }
    span {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #000000;
    }
  }
  &__mobile-change-plan {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .subscriptions {
    background: #FFFFFF;
    padding: 30px 15px;
    border-radius: 10px;
    &__change-plan {
      display: none;
    }
    &__mobile-change-plan {
      display: block;
      background: none;
      width: inherit !important;
      text-align: left;
      font-weight: bold;
      font-family: Inter, sans-serif;
      padding: 5px 0;
      text-decoration: underline;
      height: inherit !important;
    }
    &__mobile-card-edit {
      display: flex;
      width: 83px;
      height: 33px;
      align-items: center;
      justify-content: center;
      border: 1px solid #FFFFFF;
      border-radius: 100px;
      background: none;
      color: #FFFFFF;
      font-family: 'Inter', sans-serif;
      margin-right: 10px;
    }
    h2 {
      display: block !important;
      font-weight: 700;
      font-size: 21px;
      margin-bottom: 16px;
    }
    &__activate-date {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      letter-spacing: -0.5px;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 13px;
      font-family: 'Inter', sans-serif;
    }
    &__table-tbody {
      //flex-direction: column;
    }
    &__mobile-card {
      display: flex !important;
      .subscriptions__edit {
        display: none;
      }
    }
  }
  .subscriptions__table-thead {
    display: none;
  }
  .subscriptions__table-tbody {
    height: 180px;
    padding: 20px 0 !important;
    .subscriptions__thead-cols {
      display: none;
      &:first-child {
        width: 100% !important;
        padding-left: 20px !important;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .subscriptions__span {
    display: block;
    margin-top: 5px;
  }
}