.deliverables {
  &__desc {
    display: flex;
    justify-content: space-between;
    p {
      padding-top: 5px;
      ul {
        padding-left: 20px;
        line-height: 24px;
        li {
          list-style-type: disc;
        }
      }
      ol {
        padding-left: 20px;
        line-height: 24px;
        white-space: inherit !important;
        li {
          list-style-type: decimal;
        }
      }
      strong {
        font-weight: 600;
      }
      em {
        font-style: italic;
      }
      b {
        font-weight: 700;
      }
    }
    &-block {
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      margin-top: 30px;
    }
    &-edit {
      background: #F3F5FA;
      border-radius: 10px;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      flex-shrink: 0;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
