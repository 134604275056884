.task-view {
  * {
    //transition: all ease-in 0.5s;
  }
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 100%;
  &__left-side {
    flex-grow: 1;
    margin-right: 15px;
  }
}

.mobile-back-to-all-task {
  display: none;
  margin: 0 15px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #2361FF;
  margin-bottom: 20px;
  img {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 460px) {
  .client_layout {
    .task-view {
      flex-direction: column;
      padding: 0 15px;
    }
    .mobile-back-to-all-task {
      display: flex;
      width: fit-content;
    }
  }
}
