@import "./src/assets/scss/variables";

.pagination {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin: 50px auto;
}
.pagination__arrow-left {
  cursor: pointer
}

.pagination__container {
  display: flex;
  margin: 0 20px
}

.pagination__item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 5px;
  /*background-color: #f6f6f6;*/
  cursor: pointer;
  user-select: none;
  font-weight: bold;
}

.pagination__item:last-child {
  margin-right: 0
}

.pagination__item--active {
  background: $color_blue;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.pagination__item-dots {
  background-color: transparent;
  color: #bbb;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
}
.pagination__arrow-right {
  transform: rotate(180deg);
  cursor: pointer;
}
