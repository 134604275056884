.kyc_modal {
  &__body {
    &-logo {
      display: flex;
      justify-content: center;
    }
  }
  &__text {
    p {
      margin-top: 15px;
      font-weight: 300;
      line-height: 20px;
      font-size: 15px;
    }
    b {
      font-weight: bold;
      display: block;
    }
    em {
      font-style: italic;
      display: block;
      margin-top: 10px;
    }
    a {
      color: #2361FF;
    }
  }
}