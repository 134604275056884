@import "/src/assets/scss/variables.scss";

.milestones {
  &__items {
    padding: 5px 0;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    &-price {
      font-size: 15px;
      width: 10%;
    }
    &-checkbox {
      width: 80%;
    }
    .checkbox__item-element {
      border: 1px solid #CAD5DD;
      box-sizing: border-box;
      box-shadow: inset 0 0 4px rgba(155, 172, 233, 0.25);
      margin-right: 10px;
    }
    .checkbox__item-active {
      border: none;
    }
    &-edit {
      width: 10%;
      text-decoration: none !important;
    }
  }
  &__delete {
    color: #000000;
    font-size: 18px;
    margin-left: 20px;
    width: 32px;
    height: 32px;
    border: 1px solid #000000;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
    }
    &:hover {
      text-decoration: none !important;
    }
  }
  &__row {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 11px;
      line-height: 13px;
      color: #A3A9B9;
      display: inline-block;
    }
  }
}
