.frozen_modal .modal__container {
  width: 580px;
}

.frozen_modal__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin-top: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
  }
  p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-top: 24px;
    text-align: center;
  }
  b {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    margin-top: 24px;
    display: block;
    text-align: center;
  }
  button {
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 30px;
  }
}