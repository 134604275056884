.create_legal_request {
  .modal__container {
    width: 510px;
  }
  &__body {
    position: relative;
  }
  .modal__container__header h2 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    color: #000000;
  }
  &__description {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    margin-top: 20px;
  }
  &__select {
    position: relative;
    &-container {
      height: 42px;
      width: 100%;
      border: 1px solid #B4BED9;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 13px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-top: 20px;
      span {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
  &__dropdown {
    /*position: absolute;*/
    z-index: 2;
    background: #FFFFFF;
    border: 1px solid #B4BED9;
    box-sizing: border-box;
    border-radius: 5px;
    max-height: 380px;
    margin-top: 6px;
    overflow: auto;
    padding: 0 17px 32px 17px;
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #FFFFFF;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #C4D0E7;
      outline: 1px solid #C4D0E7;
      border-radius: 100px;
    }
  }
  &__need-block {
    margin-top: 30px;
    b {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
  }
  &__need-items {
    margin-top: 7px;
  }
  &__need-item {
    display: flex;
    align-items: center;
    margin-top: 11px;
    cursor: pointer;
    span {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 13px;
      margin-left: 8px;
      line-height: 16px;
    }
  }
  &__radio {
    width: 20px;
    height: 20px;
    background: #F3F5FA;
    border: 1px solid #EAE9F2;
    box-sizing: border-box;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    &-active {
      width: 14px;
      height: 14px;
      background: #3761f0;
      border-radius: 100px;
    }
  }
  &__custom-request {
    &-heading {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      margin-top: 20px;
    }
    &-input {
      margin-top: 10px;
      input::placeholder {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #A3A9B9;
      }
    }
    &-textarea {
      margin-top: 10px;
      textarea::placeholder {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #A3A9B9;
      }
    }
    &-button {
      width: 100%;
      margin-top: 30px;
    }
  }
}