@import "./src/assets/scss/variables";

.task-body {
  background: $color_light;
  border-radius: 10px;
  padding: 20px 30px;
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
    &-left {
      display: flex;
      align-items: center;
      img {
        margin-right: 14px;
      }
      &-price {
        font-family: Inter, sans-serif;
        font-size: 21px;
        font-weight: 700;
      }
      &-button {
        border-radius: 100px;
        background: $color_blue;
        border: none;
        color: $color_light;
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 11px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 11px;
        cursor: pointer;
        img {
          margin-right: 4px;
        }
      }
    }
  }
  &__description {
    table {
      display: none !important;
    }
    h2 {
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-size: 21px;
      line-height: 25.5px;
      padding-bottom: 10px;
      max-width: 400px;
    }
    h3 {
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      padding-bottom: 10px;
    }
    ul {
      padding-left: 20px;
      line-height: 24px;
      li {
        list-style-type: disc;
      }
      em, i {
        font-style: italic;
      }
      strong, b {
        font-weight: bold;
      }
    }
    ol {
      padding-left: 20px;
      line-height: 24px;
      li {
        list-style-type: decimal;
      }
      em {
        font-style: italic;
      }
      strong {
        font-weight: bold;
      }
    }
    &-block {
      display: flex;
      justify-content: space-between;
      word-break: break-word;
      p {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        margin-bottom: 2px;
        & strong, b {
          font-weight: bold;
        }
        & em, i {
          font-style: italic;
        }
        & + a {
          color: $color_blue;
          display: block;
          font-size: 13px;
          margin-top: 5px;
        }
      }
      .gmail_drive_chip {
        margin: 0 !important;
        padding: 0 !important;
        height: inherit !important;
        max-height: inherit !important;
        background: inherit !important;
        border: none !important;
      }
    }
    &-edit {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F3F5FA;
      border-radius: 10px;
      flex-shrink: 0;
      margin-left: 10px;
      &-disabled {
        cursor: auto;
        opacity: 0.5;
      }
    }
  }
  &__footer {
    margin-top: 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__user {
    display: flex;
    align-items: center;
    &-photo {
      width: 30px;
      height: 30px;
      margin-right: 5px;
      img {
        max-width: 100%;
        border-radius: 10px;
      }
    }
    &-desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h4 {
        font-family: Roboto;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: -0.5px;
      }
      em {
        color: $color_grey_light;
        font-family: Inter;
        font-size: 11px;
        line-height: 13px;
      }
    }
  }
  &__dates {
    min-width: 350px;
    &-item {
      margin-left: 31px;
      font-size: 13px;
      font-family: Inter;
      em {
        color: $color_grey_light;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .client_layout {
    .task-body {
      padding: 20px 15px;
    }
    .task-view__left-side {
      margin-right: 0;
    }
    .comment-list {
      width: 100%;
      margin-top: 20px !important;
    }
    .milestones__row {
      display: none;
    }
    .milestones__item {
      margin-top: 10px;
    }
    .comment-list__header {
      padding: 0 20px;
    }
    .task-body__footer {
      flex-wrap: wrap-reverse;
    }
    .task-body__dates {
      min-width: inherit;
    }
    .task-body__dates-item {
      margin-left: 0;
      &:first-child {
        margin-right: 15px;
      }
    }
    .task-body__user {
      margin-top: 15px;
    }
    .task-body__header-left img {
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 380px) {
  .client_layout {
    .task-body__dates {
      display: flex;
      flex-wrap: wrap;
    }
    .task-body__dates-item {
      margin-bottom: 7px;
    }
    .task-body__header-left img {
      margin-right: 5px;
    }
  }
}