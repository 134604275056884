.terms_use_privacy_policy-modal {
  &__body {
    &-logo {
      display: flex;
      justify-content: center;
    }
  }
  &__text {
    margin-top: 0;
    h2 {
      text-align: center;
      font-weight: 700;
      font-size: 28px;
      margin-top: 0px;
    }
    p {
      margin-top: 15px;
      font-weight: 300;
      line-height: 20px;
      font-size: 15px;
      text-align: center;
      a {
        text-decoration: none;
      }
    }
    b {
      font-weight: bold;
      display: block;
    }
    em {
      font-style: italic;
      display: block;
      margin-top: 10px;
    }
    a {
      color: #2361FF;
    }
  }
  &__checkbox {
    background: #F3F5FA;
    padding: 20px 30px;
    border-radius: 4px;
    &-container {
      display: flex;
      align-items: center;
    }
    &-squire {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      flex-shrink: 0;
      margin-right: 15px;
      border: 1px solid #CAD5DD;
      border-radius: 4px;
      cursor: pointer;
    }
    &-active {
      height: 8px;
      width: 8px;
      background: #2361FF;
      border-radius: 50%;
    }
    &-label {
      font-family: 'Inter', sans-serif;
      line-height: 15px;
      font-size: 13px;
      cursor: pointer;
    }
    a {
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
    /*span {
      font-size: 14px;
      font-weight: 300;
      margin-left: 10px;
    }
    .custom_checkbox_not_active {
      background: #e7e7e7;
    }*/
  }
}

.terms_use_privacy_policy {
  .modal__container {
    width: 510px;
  }
}