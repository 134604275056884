.create_milestones {
  &__heading {
    font-family: Inter;
    font-weight: 700;
    font-size: 21px;
  }
  &__body {
    margin-top: 45px;
  }
  &__save {
    width: 100%;
    margin-top: 30px;
  }
}
