.welcome_onBoarding {
  .modal__container {
    max-width: 510px;
  }
  &__img {
    display: block;
    margin: 0 auto;
  }
  &__heading {
    margin: 64px auto 0 auto;
    max-width: 318px;
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
  }
  &__description {
    margin: 14px auto 0 auto;
    max-width: 255px;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }
  &__button {
    width: 100%;
    display: block;
    max-width: 380px;
    margin: 57px auto 0 auto;
  }
}