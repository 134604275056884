@import "./src/assets/scss/variables";

.react-editor {
  display: flex;
  flex-direction: column;
  position: relative;
  .tox-statusbar__wordcount {
    display: none !important;
  }
  .tox-statusbar {
    display: none !important;
  }
  textarea {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 13px 15px;
  }
  ::placeholder {
    font-family: Inter;
    color: $color_black;
    font-size: 13px;
  }
  &__label {
    font-family: Inter;
    position: absolute;
    background: #fff;
    padding: 0 8px;
    top: -4px;
    left: 12px;
    font-size: 11px;
    color: $color_grey_light;
  }
  &__error-message {
    color: red;
    font-size: 12px;
    padding: 3px 0 0 3px;
  }
}
