@media only screen and (max-width: 460px) {
  .client_layout {
    .layout__sidebar {
      display: none;
    }
    .layout__container {
      padding: 0;
    }

    .tasks-project-kanban__header {
      position: absolute;
      z-index: 2;
      width: 100%;
      top: 65px;
      right: 0;
      padding: 8px;
      background: #f3f5fa;
    }
    .tasks-project-kanban__header-close {
      height: 35px;
      border-radius: 50px;
      background: #3761f0;
      border: none;
      color: #fff;
      margin-bottom: 10px;
      width: 100%;
      padding: 0 30px;
    }
    .sort__header {

    }
    .tasks-project-kanban__header-close em {
      font-size: 15px;
      margin-left: 3px;
    }
    .tasks-project-kanban-header-search__input {
      height: 45px;
      border-radius: 0;
    }
    .tasks-project-kanban__body {
      /*padding: 0 10px;*/
    }
    .tasks-project-kanban-header__search {
      min-width: 100%;
      margin-right: 0 !important;
    }
    .tasks-project-kanban-header__title {
      display: none;
    }
    .tasks-project-kanban-header__archive-btn {
      height: 35px;
      margin-top: 10px;
      background: #FFFFFF;
      width: 100%;
      border: none;
      text-transform: capitalize;
    }
    .sort__body {
      left: 0;
    }
    .tasks-project-kanban-header__archive-btn_active {
      background: #2361FF !important;
      color: #FFFFFF !important;
    }
    .tasks-project-kanban-header__action {
      flex-wrap: wrap;
    }
    .tasks-project-kanban-header__sort {
      width: 50%;
      padding-right: 1%;
      margin-right: 0;
    }
    .sort {
      min-width: inherit;
      padding-left: 0;
    }
    .tasks-project-kanban-header__archive {
      width: 50%;
      padding-left: 1%;
    }
    .sort__header {
      height: 35px;
      justify-content: center;
      font-weight: 400;
      cursor: pointer;
      background: #FFFFFF;
      border: none;
      padding: 0 15px;
      margin-top: 10px;
    }
    //.tasks-project-kanban-header__sort, .tasks-project-kanban-header__archive
    .tasks-project-kanban-header__dropdown {
      display: none;
    }
    .kanban-project-tabs {
      display: none;
    }
    .progress-line {
      //display: none;
    }

    .tasks-project-kanban-grid {
      flex-wrap: nowrap;
      overflow: hidden;
    }

    .tasks-project-kanban-grid__item {
      flex-shrink: 0;
    }

    .task-status__current {
      width: inherit;
    }
    .progress-line__description {
      min-width: inherit;
      align-items: baseline;
    }
    .current-budget {
      display: none;
    }
    .task-body__description h3 {
      display: none;
    }
    .task-status__current > div, .task-body__dates * {
      font-family: "Inter", sans-serif;
    }
    .task-body__description-block p + a {
      font-weight: 500;
    }
    .task-body__user-photo img {
      height: 27px;
      width: 27px;
    }
    .task-body__header-left-button {
      width: 31px;
    }
    .task-body__header-left-button img {
      margin-right: 0;
    }
    .task-body__header-left-button img + div {
      display: none;
    }
    .checkbox__item-label {
      line-height: 21px;
    }
    .user-messages__text p {
      font-family: "Inter", sans-serif;
    }
    .user-messages__img img {
      width: 48px;
      height: 48px;
    }
    .mobile-crate-task-icon {
      display: flex !important;
    }
    .modal__container {
      padding: 20px;
    }
    .tox-tinymce {
      height: 180px !important;
      width: 100%;
    }
    .tasks-project-kanban-item__project-name{
      font-size: 11px;
      line-height: 13px;
    }
    .tasks-project-kanban-item__description {
      font-size: 13px;
      line-height: 16px;
    }
    .error-boundary__img {
      max-width: 100% !important;
    }
    .tasks-project-kanban-item__date-wrap span {
      font-family: "Inter", sans-serif;
    }
  }

  .auth-modal__body {
    width: 90% !important;
    min-height: inherit !important;
  }
  .auth-modal__right-col {
    display: none !important;
  }
  .auth-cols .auth-modal__left-col {
    width: 100% !important;
    padding: 10px !important;
  }
  .client_layout .auth-modal__container {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.tasks-project-kanban-grid {
  -webkit-transition: margin-left 0.5s ease-out 0s;
  -moz-transition: margin-left 0.5s ease-out 0s;
  -o-transition: margin-left 0.5s ease-out 0s;
  transition: margin-left 0.5s ease-out 0s;
}