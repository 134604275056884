.create_manager_offer {
  .modal__container {
    max-width: 510px;
  }
  .modal__container__header {
    h2 {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
    }
  }
  .create_legal_request__need-block {
    em {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: #8A93AD;
      margin-left: 8px;
      margin-top: 2px;
    }
    strong {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #000000;
      margin-left: 7px;
    }
  }
  &__container {

  }
  &__description {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    margin-top: 20px;
  }
  &__button {
    width: 100%;
    margin-top: 51px;
    &:disabled {
      opacity: 0.5;
    }
  }
  &__button-second-step {
    width: 100%;
    margin-top: 30px;
    &:disabled {
      opacity: 0.5;
    }
  }
  &__react-select {
    margin-top: 20px;
  }
  &__input input {
    margin-top: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 13px;
  }
  &__textarea textarea {
    margin-top: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
}