.offerHeading {
  margin-top: 11px;
  &__heading {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
  }
  &__steps {
    margin-top: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;
    &-next {
      color: #8A93AD;
      font-weight: 400;
    }
    &-step {
      font-weight: 400;
    }
  }
  &__text {
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.5px;
  }
  &__edit {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #8A93AD;
  }
  &__client {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-right: 15px;
    img {
      margin-right: 12px;
      cursor: pointer;
    }
    p {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
    span {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 11px;
    }
    em {
      color: #8A93AD;
      margin-right: 3px;
    }
  }
  &__show-more {
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    color: #2361FF;
    cursor: pointer;
    margin-top: 10px;
    font-size: 13px;
    img {
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 500px) {

}