.red_flags {
  &__item {
    span {
      font-size: 13px;
      margin-left: 10px;
      padding-top: 1px;
    }
    > div {
      //align-items: flex-start;
    }
  }
  &__container {
    h2 {
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
      color: #000000;
    }
  }
}