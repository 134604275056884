.card_dropdown {
  position: relative;
  img {
    cursor: pointer;
  }
  &__squire {
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: 2;
    background: #FFFFFF;
    transform: rotate(45deg);
    right: -17px;
    top: 7px;
  }
  &__container {
    box-shadow: 0px 4px 24px rgba(15, 31, 74, 0.2);
    display: flex;
    flex-direction: column;
    min-width: 125px;
    border-radius: 10px;
    position: absolute;
    background: #fff;
    z-index: 1;
    left: 36px;
    top: -4px;
    a {
      padding: 9px 8px 9px 16px;
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 400;
      border-bottom: 1px solid #F3F5FA;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}