.singleOffer {
  &__deliverables-task {
    background: #ffffff;
    border-radius: 10px;
    padding: 9px 0 5px 0;
    margin-top: 6px;
    &-title {
      padding: 0 10px 4px 15px;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.5px;
      border-bottom: 1px solid #CAD5DD;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      p {
        padding-top: 3px;
      }
      &-fee_exempt {
        background: #898989;
        color: #FFFFFF;
        font-size: 11px;
        padding: 2px 10px;
        border-radius: 30px;
        height: 21px;
        margin-right: 5px;
        margin-top: -4px;
      }
    }
    &-photos {
      padding: 0 15px;
    }
    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-label {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      color: #A3A9B9;
      padding: 0 5px;
    }
    &-status {

    }
    &-text {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      margin-top: 2px;
      padding: 0 5px;
    }
  }

  &__tabs {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 0 30px;
    display: flex;
    &-item {
      padding: 20px 0;
      margin-right: 30px;
      color: #2361FF;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      opacity: 0.5;
      display: flex;
      align-items: center;
      position: relative;
      &-active {
        opacity: 1;
        border-bottom: 3px solid #2361FF;
        box-sizing: border-box;
        padding-bottom: 17px;
      }
    }
    &__counter {
      position: absolute;
      border-radius: 50%;
      background: #FF5C5C;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      padding: 0 6px;
      font-size: 11px;
      height: 19px;
      box-sizing: border-box;
      font-weight: normal !important;
      margin-left: 15px;
      font-family: 'Inter', sans-serif;
      right: -27px;
    }
  }
}

.full-screen-chat {
  .task-view__left-side {
    overflow: hidden;
    width: 0;
    height: 0;
  }
  .task-view__right-side, .comment-list {
    width: 100%;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 500px) {
  .singleOffer {
    .client_kanbanStep_notifications__offer {
      margin: -20px -15px 0 -15px;
      height: 40px;
    }
  }
}