.task-view-button {
  width: 100%;
  height: 65px;
  background: linear-gradient(0deg, #EEF0F4, #EEF0F4), #FFFFFF;
  border: 1px dashed #77A193;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  font-family: Inter;
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  &-add {
    background: #000000;
    border-radius: 5px;
    height: 35px;
    width: 35px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-right: 10px;
  }
}
