.have-questions {
  padding: 20px 15px;
  background: #FFFFFF;
  border-radius: 10px;
  width: 226px;
  height: 202px;
  margin-left: 30px;
  box-sizing: border-box;
  margin-top: 10px;
  cursor: pointer;
  h2 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  &__icon {
    margin: 5px auto 0 auto;
    display: block;
  }
  &__footer {
    // margin-top: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(0, 18, 66, 0.1);
    margin: 13px -15px 0 -15px;
    padding: 6px 15px 10px 15px;
    span {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 11px;
      text-transform: uppercase;
      a {
        color: #4569E9;
      }
    }
  }
  &__button {
    width: 24px;
    height: 24px;
    background: #000000;
    border: 1px solid #000000;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}