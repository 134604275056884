.onBoarding {
  &__header {
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: end;
      h1 {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 26px;
        line-height: 31px;
      }
      span {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        padding-bottom: 3px;
        margin-left: 27px;
      }
    }
  }

  &__statuses {
    display: flex;
  }
  &__status {
    display: flex;
    align-items: center;
    &-circle {
      width: 26px;
      height: 26px;
      background: rgba(0, 171, 48, 0.5);
      border: 1px solid #18A548;
      box-sizing: border-box;
      border-radius: 100px;
      margin-right: 8px;
      &-progress {
        background: rgba(255, 191, 26, 0.7);
        border: 1px solid #FFB258;
      }
    }
    span {
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
    &:last-child {
      margin-left: 67px;
    }
  }
  &__table {
    width: 100%;
    margin-top: 36px;
  }
  &__thead {
    display: flex;
    padding: 0 34px;
    margin-left: 79px;
    &-th {
      padding: 10px;
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-size: 11px;
      color: #9DA6BF;
      &-id {
        padding-left: 0;
        width: 32px;
        flex-shrink: 0;
      }
      &-title {
        width: calc(50% + 20px);
        flex-shrink: 0;
      }
      &-info {
        width: calc(30% + 20px);
      }
      &-action {
        flex-grow: 1;
      }
    }
  }
  &__tbody {
    display: flex;
    flex-direction: column;
    position: relative;
    &-text {
      font-family: Inter, sans-serif;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      padding: 20px 0;
    }
    &-item {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 10px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: calc(100% - 70px);
      top: 40px;
      left: 15px;
      border-right: 2px dashed #D7DDEC;
      z-index: 0;
    }
  }
  &__iconCol {
    width: 79px;
    position: relative;
    z-index: 1;
  }
  &__colIcon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D7DDEC;
    border-radius: 5px;
    border: 1px solid #C4CCE0;
    &-wait {
      background: #f9da8a;
      border: 1px solid #FFB258;
    }
    &-click {
      background: #f9da8a;
      border: 1px solid #FFB258;
    }
    &-ok {
      background: #79d095;
      border: 1px solid #18A548;
    }
  }
  &__col {
    min-height: 80px;
    display: flex;
    align-items: center;
    padding: 21px 34px;
    background: #FFFFFF;
    border-radius: 10px;
    flex-grow: 1;
    &-id, &-title, &-info {
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      padding: 0 10px;
    }
    &-id {
      padding-left: 0;
      width: 32px;
      flex-shrink: 0;
    }
    &-title {
      width: calc(50% + 20px);
      flex-shrink: 0;
      b {
        display: none;
      }
    }
    &-info {
      width: calc(30% + 20px);
    }
    &-action {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__button {
    padding: 0 19px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: black;
    border-radius: 100px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: -0.5px;
    color: #FFFFFF;
    cursor: pointer;
    min-width: 122px;
    &:disabled {
      opacity: 0.2;
      cursor: default;
    }
  }
  &__open-item {
    cursor: pointer;
  }
  &__offer {
    min-height: 80px;
    display: flex;
    align-items: center;
    padding: 21px 34px;
    background: #FFFFFF;
    border-radius: 10px;
    flex-grow: 1;
    justify-content: space-between;
    &-container {
      margin-top: 5px;
      padding-left: 79px;
    }
    &-title {
      max-width: 280px;
      font-family: Inter, sans-serif;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      width: 40%;
    }
    &-status {
      padding: 6px 10px;
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-size: 9px;
      text-transform: uppercase;
      color: #FF7A00;
      border: 1px solid #FF7A00;
      border-radius: 100px;
    }
    &-price {
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 700;
      width: 10%;
    }
    &-user {
      display: flex;
      align-items: center;
      width: 10%;
      &-avatar {
        width: 27px;
        height: 27px;
        img {
          width: 27px;
          height: 27px;
          border-radius: 10px;
        }
      }
      &-detail {
        margin-left: 5px;
        b {
          font-weight: 400;
          font-size: 13px;
          letter-spacing: -0.5px;
        }
        p {
          font-weight: 400;
          font-size: 11px;
          color: #8A93AD;
        }
      }
    }
    &-actions {
      display: flex;
      width: 20%;
      > div {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 11px;
        letter-spacing: -0.5px;
        width: 93px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
      }
    }
    &-detail {
      border: 1px solid #B4BED9;
      margin-right: 7px;
    }
    &-accept {
      margin-right: 7px;
      color: #FFFFFF;
      background: #000000;
    }
    &-accepted {
      border: 1px solid #3AAE6F;
      background: #FFFFFF;
      color: #000000;
      cursor: default !important;
      > div {
        width: 11px;
        height: 11px;
        background: #3AAE6F;
        border-radius: 100px;
        margin-left: 12px;
      }
    }
  }
  &__pay-invoice-button {
    padding: 0 19px;
    height: 35px;
    width: 136px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 460px) {
  .onBoarding {
    padding: 15px;
    &__header {
      flex-direction: column;
      &-left {
        flex-direction: column;
        align-items: flex-start;
        h1 {
          font-weight: 700;
          font-size: 21px;
        }
        span {
          margin-left: 0;
          margin-top: 10px;
          font-weight: 400;
          font-size: 13px;
        }
      }
    }
    &__status {
      margin-top: 20px;
    }
    &__table {
      margin-top: 20px;
    }
    &__thead {
      display: none;
    }
    &__tbody {
      &-item {
        margin-top: 20px;
        &:first-child {
          align-items: flex-start;
        }
        &:last-child {
          align-items: flex-end;
        }
      }
    }
    &__col {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      > div {
        width: 100%;
        padding: 0;
      }
      &-id {
        font-family: Inter, sans-serif;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
      }
      &-title {
        font-family: Inter, sans-serif;
        font-size: 13px;
        line-height: 19px;
        b {
          display: block;
          font-weight: 700;
          font-size: 11px;
          line-height: 13px;
          color: #9DA6BF;
          margin-top: 20px;
          margin-bottom: 6px;
        }
      }
      &-info {

      }
      &-action {
        margin-top: 50px;
        a {
          width: 100%;
        }
        button {
          width: 100%;
          height: 42px;
        }
      }
    }
    &__iconCol {
      width: 32px;
      margin-right: 10px;
    }
  }
}