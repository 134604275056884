.global-search {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &__result-count {
    width: 200px;
    padding-right: 10px;
    p {
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      width: 100%;
    }
  }
  &__main-block {
    width: 558px;
  }
  &__order {
    .sort_kanban_board__current {
      border-radius: 4px;
    }
  }
  &__search {
    height: 42px;
    background: #FFFFFF;
    flex-shrink: 0;
    border: 1px solid #EEEFF6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      width: 100%;
      height: 40px;
      outline: none;
      border: none;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 4px;
    }
    img {
      flex-shrink: 0;
      padding: 0 10px;
      cursor: pointer;
    }
  }
  &__result {
    margin-top: 10px;
  }
  &__result-item {
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 5px;
    cursor: pointer;
    &-breadcrumbs {
      color: #919AB3;
      font-size: 11px;
      height: 37px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #E5EAF8;
    }
    &-avatar {
      width: 58px;
      flex-shrink: 0;
      img {
        height: 48px;
        width: 48px;
        padding: 5px;
        display: block;
        margin: 0 auto;
        flex-shrink: 0;
      }
    }
    &-main {
      display: flex;
      padding: 15px 10px 12px 0;
      &-text {
        flex-grow: 1;
      }
      p {
        font-size: 13px;
        line-height: 21px;
        margin-top: 7px;
        i {
          font-weight: bold;
          background: #ffd782;
        }
      }
      &-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        b {
          font-family: 'Inter', sans-serif;
          font-size: 13px;
          font-weight: bold;
        }
        em {
          color: #8A93AD;
          font-size: 13px;
        }
      }
    }
  }
  &__file {
    display: flex;
    align-items: center;
    margin-top: 5px;
    &-img {
      width: 36px;
      height: 36px;
      background: #2361FF;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
    span {
      font-size: 13px;
      margin-left: 10px;
    }
    button {
      color: #7389C3;
      border-radius: 20px;
      border: 1px solid #E5EAF8;
      font-size: 11px;
      background: #FFFFFF;
      padding: 5px 10px;
      margin-left: 20px;
      cursor: pointer;
      &:hover {
        background: #E5EAF8;
      }
    }
  }
}