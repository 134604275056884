.payment-method {
  &__table {
    width: 100%;
    &-thead {
      display: flex;
      width: 100%;
    }
    &-tbody {
      display: flex;
      width: 100%;
      border: 1px solid #F3F5FA;
      border-radius: 10px;
      height: 123px;
      padding-top: 33px;
      margin-bottom: 10px;
    }
  }
  &__thead-cols {
    width: 15%;
    display: flex;
    flex-direction: column;
    &-w40 {
      width: 40%;
      padding-left: 40px;
    }
    &-w30 {
      width: 30%;
    }
  }
  &__th {
    font-size: 11px;
    line-height: 13px;
    color: #A3A9B9;
    padding-bottom: 10px;
  }
  &__h4 {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 5px;
  }
  &__span {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #000000;
  }
  &__date {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #000000;
  }
  &__actions {
    display: flex;
    padding-left: 10px;
  }
  &__card {
    display: flex;
    align-items: flex-start;
    img {
      margin-right: 8px;
    }
    p {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.5px;
      color: #000000;
      margin-top: -2px;
    }
    &-link {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.5px;
      color: #000000;
      display: block;
      margin-top: 6px;
    }
  }
  &__button {
    cursor: pointer;
    &-container {
      background: linear-gradient(0deg, #EEF0F4, #EEF0F4), #FFFFFF;
      border: 1px dashed #77A193;
      box-sizing: border-box;
      border-radius: 10px;
      height: 123px;
      width: 100%;
      display: flex;
      align-items: center;
    }
    &-icon {
      background: #000000;
      border-radius: 5px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 40px;
      margin-right: 10px;
    }
    span {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #000000;
    }
  }
}
