.vlo-partner-request {
  background: #FFFFFF;
  padding: 30px 40px;
  border-radius: 10px;
  h4 {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 21px;
    font-weight: 700;
  }
  &__button {
    width: 100%;
    height: 55px;
    background: linear-gradient(0deg,#eef0f4,#eef0f4),#fff;
    border: 1px dashed #77a193;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 30px auto 0 auto;
    &:hover {
      opacity: 0.5;
    }
    span {
      font-family: "Inter",sans-serif;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #000;
      margin-left: 10px;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  &__circle {
    width: 35px;
    height: 35px;
    background: #000;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__item {
    &-button {
      width: 33px;
      height: 33px;
      border-radius: 10px;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  &__provider {
    width: 27px;
    height: 27px;
    border-radius: 10px;
    //cursor: pointer;
  }
}

.vlo-partner-request-modal {
  &__description {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 20px;
  }
}