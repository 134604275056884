.create_task {
  &__heading {
    font-family: Inter;
    font-weight: 700;
    font-size: 21px;
  }
  &__mt {
    margin-top: 15px;
  }
  &__save {
    width: 100%;
  }
  &__label {
    z-index: 6;
    font-family: Inter;
    position: relative;
    background: #fff;
    padding: 0px 8px;
    top: 5px;
    left: 12px;
    font-size: 11px;
    color: #8A93AD;
  }
}
