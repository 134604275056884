.all_approve_deliverables  {
  h4 {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    max-width: 600px;
    margin: 10px auto 0 auto;
  }
  .modal__container {
    max-width: 920px;
    width: 920px;
  }
  &__tabs {
    width: 100%;
  }
  &__tab-item {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 21px;
    border-bottom: 4px solid #2361FF;
    text-align: center;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-icon {
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      font-size: 11px;
      color: #FFFFFF;
      height: 20px;
      background: #FF5C5C;
      border-radius: 100px;
      display: inline-block;
      line-height: 20px;
      padding: 0 7px;
      margin: 3px 7px;
    }
  }
  &__list {
    margin-top: 50px;
    &-heading {
      display: flex;
      align-items: center;
      color: #81889A;
      margin-bottom: 9px;
      span {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 13px;
        padding-left: 15px;
        &:first-child {
          width: 520px;
        }
      }
    }
    &-container {
      display: flex;
      width: 100%;
    }
    &-editor {
      margin-top: 5px;
    }
    &-item {
      border: 1px solid #F3F5FA;
      border-radius: 10px;
      padding: 29px 20px;
      margin-bottom: 10px;
    }
    &-description {
      width: 500px;
    }
    &-name {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 17px;
    }
    &-link {
      margin-top: 8px;
      font-family: 'Inter', sans-serif;
      font-size: 17px;
      color: #2361FF;
      display: flex;
      align-items: center;
    }
    &-copy {
      width: 28px;
      height: 28px;
      border: 1px solid #BBC8DA;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
      cursor: pointer;
    }
  }


  &__button {
    font-family: 'Inter', sans-serif;
    width: 130px;
    padding: 11px 0;
    border-radius: 24px;
    background: #000;
    color: #fff;
    font-weight: 500;
    font-size: 11px;
    cursor: pointer;
    border: 2px solid #000;
    text-transform: uppercase;
    letter-spacing: 1px;
    &:hover {
      opacity: 0.5;
    }
    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }
  &__deny {
    margin-left: 5px;
    color: #000;
    background: #FFFFFF;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 500px) {
  .all_approve_deliverables {
    h4 {
      font-weight: 500;
      font-size: 13px;
    }
    &__list {
      margin-top: 10px;
      &-buttons {
        button {
          width: 50%;
          margin: 0;
          &:first-child {
            border-radius: 0 0 0 12px !important;
          }
          &:last-child {
            border-radius: 0 0 12px 0 !important;
          }
        }
      }
      &-description {
        width: 100%;
        padding: 20px 10px 10px 10px;
      }
      &-name {
        font-size: 15px;
        line-height: 13px;
      }
      &-heading {
        display: none;
      }
      &-container {
        flex-direction: column;
      }
      &-item {
        padding: 0;
      }
      &-link {
        margin-top: 0;
        a {
          max-width: calc(100% - 40px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: 500;
          font-size: 13px;
        }
      }
      &-copy {
        width: 36px;
        height: 36px;
      }
    }
  }
}