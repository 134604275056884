.offerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #F3F5FA;
  &__publish-button {
    width: 165px;
    background: #F3B71B;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-right: 10px;
  }
  &__dropdown {
    position: relative;
    &-container {
      position: absolute;
      min-width: 200px;
      padding: 15px 15px;
      background: #ffffff;
      box-shadow: 0 4px 24px rgba(15, 31, 74, 0.2);
      border-radius: 10px;
      margin-top: 3px;
      right: -20px;
      top: 28px;
      left: inherit !important;
      z-index: 2;
    }
  }
  &__left {
    display: flex;
  }
  &__status {
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    text-transform: uppercase;
  }
  &__date {
    margin-bottom: 5px;
    b {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 11px;
      text-transform: uppercase;
      color: #8A93AD;
    }
    span {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 11px;
    }
  }
  &__right {
    display: flex;
    align-items: center;
    .dropdown__notifications__relative {
      margin-right: 15px;
    }
    .task-status__current > div {
      font-size: 11px;
      padding-right: 10px;
    }
    .task-status__body-item {
      font-size: 11px;
      text-transform: uppercase;
    }
    .task-status__body {
      min-width: 220px;
      left: -60px;
    }
    &-dots {
      cursor: pointer;
    }
    &-icon {
      margin-right: 24px;
    }
    .dropdown__chat-icon {

    }
  }
  &__button {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 500px) {
  .offerHeader {
    &__back-icon {
      display: none;
    }
  }
}