.partner-request-view {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f3f5fa;
    background: rgb(243, 183, 27);
    padding: 12px 20px;
    border-radius: 10px 10px 0px 0px;
    &-status {
      display: flex;
      align-items: center;
      font-family: "Inter",sans-serif;
      font-weight: 600;
      font-size: 11px;
      line-height: 11px;
      text-transform: uppercase;
      color: rgb(255, 255, 255);
      img {
        margin-right: 9px;
      }
    }
  }
  &__body {
    background: #FFFFFF;
    padding: 15px 30px;
  }
  &__title {
    font-family: "Inter",sans-serif;
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    padding: 7px 0;
  }
  &__key-value {
    display: flex;
    align-items: center;
    &-key {
      font-family: "Inter",sans-serif;
      font-size: 11px;
      font-weight: 400;
      text-transform: uppercase;
      color: #8a93ad;
    }
    &-value {
      font-family: "Inter",sans-serif;
      font-size: 11px;
      font-weight: 500;
      margin-left: 3px;
    }
  }

  &__description {
    table {
      display: none !important;
    }
    h2 {
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-size: 21px;
      line-height: 25.5px;
      padding-bottom: 10px;
      max-width: 400px;
    }
    h3 {
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      padding-bottom: 10px;
    }
    ul {
      padding-left: 20px;
      line-height: 24px;
      li {
        list-style-type: disc;
      }
    }
    ol {
      padding-left: 20px;
      line-height: 24px;
      li {
        list-style-type: decimal;
      }
    }
    &-block {
      display: flex;
      justify-content: space-between;
      word-break: break-word;
      p {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        margin-bottom: 2px;
        & strong {
          font-weight: bold;
        }
        & em {
          font-style: italic;
        }
        & + a {
          color: #2f7dd9;
          display: block;
          font-size: 13px;
          margin-top: 5px;
        }
      }
    }
    &-edit {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F3F5FA;
      border-radius: 10px;
      flex-shrink: 0;
      margin-left: 10px;
      &-disabled {
        cursor: auto;
        opacity: 0.5;
      }
    }
  }
}

.partner-request-task-tab {
  margin-top: 10px;
  background: #FFFFFF;
  padding-bottom: 30px;
  &__heading {
    background: #F3B71B;
    border-radius: 10px 10px 0 0;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
    span {
      font-family: "Inter",sans-serif;
      font-weight: 400;
      font-size: 11px;
      line-height: 11px;
      text-transform: uppercase;
      color: white;
    }
  }
  &__description {
    font-family: "Inter",sans-serif;
    text-align: left;
    font-size: 12px;
    max-width: 600px;
    padding: 10px 30px;
  }
  &__button {
    width: calc(100% - 30px);
    height: 55px;
    background: linear-gradient(0deg,#eef0f4,#eef0f4),#fff;
    border: 1px dashed #77a193;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    span {
      font-family: "Inter",sans-serif;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #000;
      margin-left: 10px;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  &__circle {
    width: 35px;
    height: 35px;
    background: #000;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.confirm-partner-request {
  width: 480px;
  margin-bottom: 10px;
  &__heading {
    background: #F3B71B;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    p {
      font-size: 12px;
      font-weight: bold;
      margin-left: 10px;
    }
  }
  &__body {
    background: #FFFFFF;
    padding: 20px 15px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      width: 50%;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
    &-not {
      border-top: 1px solid #F3F5FA;
      background: #FFFFFF;
      border-radius: 0 0 0 10px;
      color: #FF5C5C;
    }
    &-yes {
      background: #000000;
      color: #FFFFFF;
      border-radius: 0 0 10px 0;
    }
  }
}

.publish-partner-request {
  background: #FFFFFF;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 50px 20px;
  &__right {
    width: 180px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    color: #81889A;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.5px;
    max-width: 300px;
    padding-top: 5px;
  }
  h4 {
    color: #000000;
    font-weight: bold;
    font-size: 16px;
    b {
      font-size: 21px;
    }
  }
  button {
    margin-top: 10px;
  }
}

.partner-request-payment-confirm {
  margin-top: 15px;
  &__heading {
    display: flex;
    align-items: center;
    background: #F3B71B;
    padding: 15px 20px;
    border-radius: 10px 10px 0 0;
    span {
      color: #FFFFFF;
      text-transform: uppercase;
      margin-left: 15px;
      font-weight: bold;
      font-size: 11px;
      display: block;
      position: relative;
      top: 2px;
    }
  }
  &__body {
    background: #FFFFFF;
    padding: 20px 40px;
    &-table {
      border-radius: 10px;
      display: flex;
      border: 1px solid #e7e7e7;
      div {
        width: 222px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-left: 1px solid #e7e7e7;
        flex-shrink: 0;
        button:hover {
          opacity: 0.5;
        }
      }
    }
    &-td {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      height: 100%;
      p {
        font-size: 11px;
        max-width: 280px;
      }
    }
  }
}

.pr-client-tasks-accepted {
  width: 480px;
  margin-bottom: 10px;
  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    padding: 15px 20px;
    background: #FFFFFF;
    border-radius: 10px 10px 0 0;
    span {
      font-size: 11px;
      margin-left: 10px;
      font-weight: bold;
    }
  }
  &__body {
    background: #FFFFFF;
    padding: 15px 20px;
    border-radius: 0 0 10px 10px;
    em {
      font-family: "Inter",sans-serif;
      font-size: 11px;
      font-weight: 400;
      text-transform: uppercase;
      color: #8a93ad;
    }
    p {
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      margin-top: 10px;
    }
    a {
      color: #2361FF;
      display: block;
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.provider-profile-for-vlo {
  width: 100%;
  border-radius: 10px;
  background: #FFF;
  padding: 18px 30px;
  margin-bottom: 20px;
  h1 {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 700;
  }
}

.pr-switch {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 14px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 0 10px;
    }
    :hover {
      opacity: 0.5;
    }
  }
  &__button {
    width: 33px;
    height: 33px;
    background: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    &-reversed {
      transform: rotate(180deg);
      padding-right: 3px;
    }
  }
}

.pr-not-interested {
  border-radius: 10px;
  margin-top: 10px;
  &__heading {
    border-radius: 10px 10px 0 0;
    background: #FF5C5C;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    span {
      color: #FFF;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 600;
      text-transform: uppercase;
      margin-left: 10px;
    }
  }
  &__body {
    background: #FFFFFF;
    padding: 20px;
    p {
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 400;
      line-height: 16px; /* 145.455% */
      letter-spacing: -0.5px;
      text-align: center;
      max-width: 400px;
      margin: 0 auto;
    }
  }
}