.dropdown {
  position: relative;
  &__disabled {
    opacity: 0.5;
    cursor: auto !important;
  }
  &__button {
    background: #F3F5FA;
    border-radius: 10px;
    height: 32px;
    width: 32px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 15px;
  }
  &__container {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(15, 31, 74, 0.2);
    border-radius: 10px;
    padding: 15px;
    margin-top: 5px;
    width: max-content;
    right: 0;
    &:after {

    }
  }
  &__link {
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
