@import "src/assets/scss/variables";
.login-page {
  height: 100vh;
  display: flex;
  &__input {
    width: 100%;
    margin-top: 15px;
    input {
      background: #FFFFFF !important;
    }
  }
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  &__form {
    width: 50%;
    background: #f5f9fc;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-header {
      padding: 24px 20px;
    }
    &-container {
      width: 390px;
      display: flex;
      flex-direction: column;
      align-self: center;
      h2 {
        font-weight: bold;
        font-size: 21px;
        line-height: 25px;
      }
      span {
        font-size: 13px;
        margin: 7px 0 10px 0;
        a {
          cursor: pointer;
          font-weight: 600;
          color: #000;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &-footer {
      span {
        padding: 0 0 20px 20px;
        display: block;
        font-size: 12px;
      }
    }
  }
  &__forgot-link {
    font-size: 13px;
    margin-top: 8px;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__btn {
    margin-top: 42px;
    width: 100%;
    font-size: 12px;
  }
  &__banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background: linear-gradient(154.08deg, #DBFFF9 18.75%, #FAE7E3 84.23%);
    h1 {
      max-width: 620px;
      font-weight: 500;
      font-size: 38px;
      line-height: 46px;
      padding-top: 70px;
      b {
        color: $color_blue;
        font-weight: 800;
      }
    }
  }
  &__read-more {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-top: 70px;
    color: $color_black;
    text-decoration: none;
    &:hover .login-page__arrow {
      margin-left: 16px;
    }
  }
  &__arrow {
    transform: rotate(90deg);
    margin-left: 12px;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
  }
}


@media only screen and (max-width: 460px) {
  .login-page__form {
    width: 100%;
  }
  .login-page__banner {
    display: none;
  }
  .login-page__form-container {
    width: inherit;
    padding: 10px;
  }
}